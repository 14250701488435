import React, { useState, useEffect } from 'react';

function Qcm_mobile_Index() {
  
  return (
    <>
      <div className="col-lg-6 col-12">
        <h2 className="mb-4"><img src={window.location.origin +'/images/images/logo/portfolio.png'} className='exp_img'/>&nbsp;Techniques</h2>
      </div>

    <center>
        <div className='technique'>
            <img src={window.location.origin +'/images/images/mobile.png'}/>
        </div>

        <div className='container tags' align="left">
            <h3>QCM</h3>
            

            <ul className="ul_tags">
                <li>Android Studio</li>
                <li>Base de données</li>
                <li>Java</li>
            </ul>
            <b>17 Avril 2023</b>
            <hr></hr>
            <p>
              Cette application, développée en Java, est composée de deux sections : une partie pour l’utilisateur et une autre pour l’administrateur. Le rôle de l’administrateur est de créer des questionnaires, de sélectionner les meilleures réponses, puis d’assigner ces questionnaires aux utilisateurs. Ensuite, chaque utilisateur se connecte pour traiter le quiz qui lui est destiné.
            </p>
        </div>
    </center>
    </>
)};

export default Qcm_mobile_Index;