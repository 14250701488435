import React, { useState, useEffect } from 'react';

function Ecommerce_Index() {
  
  return (
    <>
      <div className="col-lg-6 col-12">
        <h2 className="mb-4"><img src={window.location.origin +'/images/images/logo/portfolio.png'} className='exp_img'/>&nbsp;Techniques</h2>
      </div>

    <center>
        <div className='technique'>
            <img src={window.location.origin +'/images/images/ecommerce.jpg'}/>
        </div>

        <div className='container tags' align="left">
            <h3>Ecommerce (Web)</h3>

            <ul className="ul_tags">
                <li>Symfony</li>
                <li>API Restful</li>
                <li>CKEditor</li>
                <li>HTML5</li>
                <li>CSS</li>
                <li>Javascript</li>
                <li>Docker</li>
                <li>Elasticsearch</li>
                <li>Bootstrap</li>
                <li>MySQL</li>
                <li>CI/CD</li>
                <li>Design UX</li>
            </ul>
            <b>01 Mars 2023</b>
            <hr></hr>
            <p>
            Objectif et réflexions autour du projet :<a href='https://gitlab.com/Youssouf128/zicho-projet'>https://gitlab.com/Youssouf128/zicho-projet</a><br></br>
<br></br>Le projet Zicho est né d'une volonté d'apporter une solution de commerce en ligne efficace, répondant aux besoins des vendeurs ainsi qu'à ceux des clients. Il se concentre sur l'offre d'une variété de produits à des prix compétitifs, tout en offrant une expérience utilisateur fluide et agréable.
Ce sujet a été choisi pour plusieurs raisons. Premièrement, le commerce en ligne est un secteur en croissance constante, notamment du fait de l'évolution des technologies numériques et de l'augmentation du nombre d'utilisateurs d'Internet. En outre, la pandémie de COVID-19 a fortement contribué à l'accélération de cette tendance, de nombreux consommateurs ayant recours à l'achat en ligne pour respecter les mesures de distanciation sociale.
Deuxièmement, ce projet nous a permis d'explorer et d'exploiter une variété de technologies, dont le framework Symfony, l'API Typesense pour la recherche, l'API Google Maps pour la localisation, et bien d'autres. Ces technologies sont essentielles pour développer une plateforme de commerce en ligne robuste et performante.
Enfin, ce projet a offert l'opportunité de créer une solution répondant à un réel besoin du marché, tout en contribuant à notre apprentissage et à notre développement en tant que développeurs.
En résumé, le projet Zicho s'inscrit dans l'objectif d'apporter une contribution significative au monde du commerce en ligne, tout en offrant une opportunité d'apprentissage et de développement des compétences techniques et pratiques.
            </p>
        </div>
    </center>
    </>
)};

export default Ecommerce_Index;