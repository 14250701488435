import React, { useState, useEffect } from 'react';

function Sanofi_Index() {
  
  return (
    <>
      <div className="col-lg-6 col-12">
        <h2 className="mb-4"><img src={window.location.origin +'/images/images/logo/portfolio.png'} className='exp_img'/>&nbsp;Techniques</h2>
      </div>

    <center>
        <div className='technique'>
            <img src={window.location.origin +'/images/images/sanofi.png'}/>
        </div>

        <div className='container tags' align="left">
            <h3>Carnet de l'operateur</h3>
            

            <ul className="ul_tags">
                <li>Node js</li>
                <li>React js</li>
                <li>API Restful</li>
                <li>CKEditor</li>
                <li>HTML5</li>
                <li>CSS</li>
                <li>Bootstrap</li>
                <li>Microsoft SQL Server</li>
                <li>CI/CD</li>
                <li>Docker</li>
                <li>Sequelize</li>
                <li>Design UX</li>
            </ul>
            <b>18 mars 2024</b>
            <hr></hr>
            <p>
            L’application « Carnet de l’Opérateur » est une application Web visant à numériser les documents de formation et à offrir un accès facile et direct à ces ressources numériques. En effet, les opérateurs avaient un besoin d’accéder à une bibliothèque de connaissances numériques à leur poste de travail. Pour ce faire, des tablettes tactiles ont été commandées et l’application « Carnet de l’Opérateur » a été développée pour utilisation sur tablettes et PC.
            
            <br></br><br></br>
                Guide Administrateur : <a href='https://drive.google.com/file/d/1JU3avpIwEbLNB21AXO0sIuzYWWbFjD5M/view?usp=drive_link' target='_blank'>https://drive.google.com/file/d/1JU3avpIwEbLNB21AXO0sIuzYWWbFjD5M/view?usp=drive_link</a>
            <br></br>
                Guide utilisateur : <a href='https://drive.google.com/file/d/1BjtLNAO_O1xILv-tDzumRtZJU86SLifM/view?usp=drive_link' target='_blanck'>https://drive.google.com/file/d/1BjtLNAO_O1xILv-tDzumRtZJU86SLifM/view?usp=drive_link</a>
            </p>
        </div>
    </center>
    </>
)};

export default Sanofi_Index;