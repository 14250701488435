import React, { useState, useEffect } from 'react';

function Chatgpt_Index() {
  
  return (
    <>
      <div className="col-lg-6 col-12">
        <h2 className="mb-4"><img src={window.location.origin +'/images/images/logo/portfolio.png'} className='exp_img'/>&nbsp;Techniques</h2>
      </div>

    <center>
        <div className='technique'>
            <img src={window.location.origin +'/images/images/ia.jpeg'}/>
        </div>

        <div className='container tags' align="left">
            <h3>Chatgpt (RedactIn)</h3>
            

            <ul className="ul_tags">
                <li>Node js</li>
                <li>Vue js</li>
                <li>HTML5</li>
                <li>CSS</li>
                <li>Tailwincss</li>
                <li>MySQL</li>
                <li>API ChatGPT</li>
                <li>Api REST</li>
                <li>CI/CD</li>
            </ul>
            <b>10 Mai 2023</b>
            <hr></hr>
            <p>

RedactIn est une application web qui aide les utilisateurs à générer des idées et à rédiger des posts LinkedIn ou à reformuler leurs textes. Par exemple, l’utilisateur entre le thème de son post, sélectionne la langue, indique la longueur souhaitée (court ou long) et choisit le pronom ("je" ou "vous"). Ensuite, l'application génère automatiquement le post en fonction de ses préférences.
            </p>
        </div>
    </center>
    </>
)};

export default Chatgpt_Index;