import React, { useState, useEffect } from 'react';
import { NavLink } from 'react-router-dom';


function Project_pages_index() {

  const [filter, setFilter] = useState("all");

  const handleFilterChange = (category) => {
    setFilter(category);
  };
  

  return (
    <>
      <div className="col-lg-6 col-12">
        <h2 className="mb-4"><img src='images/images/logo/portfolio.png' className='exp_img'/>&nbsp;Projects</h2>
      </div> 

    <center>
    <div className='container'>
        <div align="center" className='btn_project'>
          <button style={{ backgroundColor: filter === "all" ? "var(--primary_principal)" : "white", color: filter === "all" ? "white" : "black" }} className='btn' onClick={() => handleFilterChange("all")}>All</button>
          <button style={{ backgroundColor: filter === "mobile" ? "var(--primary_principal)" : "white", color: filter === "mobile" ? "white" : "black" }} className='btn' onClick={() => handleFilterChange("mobile")}>Mobile App</button>
          <button style={{ backgroundColor: filter === "web" ? "var(--primary_principal)" : "white", color: filter === "web" ? "white" : "black" }} className='btn' onClick={() => handleFilterChange("web")}>Developpement Web</button>
        </div>
      <hr></hr>
      <div style={{ marginTop: "20px" }}>
        
        {(filter === "all" || filter === "mobile") && (
          <>
            <div className='card_projects' align="left">
                <img src='images/images/ecommerce.jpg'/>

                <h5>E-commerce (Mobile)</h5>
                <p>01 Mars 2023</p>
                <p><b>Application E-Commerce(React Native)</b></p>
                <br></br>
              <center>
                <NavLink to={'/project/ecommerce'} className='btn text-white'>Voir plus</NavLink>
                <a href='#' className='btn text-white'><i class="bi bi-github"></i>&nbsp;Github</a>
                <a href='' className='btn text-white'>Demo</a>
              </center>
            </div>
            
            <div className='card_projects' align="left">
                <img src='images/images/mobile.png'/>

                <h5>QCM (Mobile)</h5>
                <p>17 Avril 2023</p>
                <p><b>Application mobile QCM ( Android Studio )</b></p>
                <br></br>
              <center>
                <NavLink to={'/project/qcm'} className='btn text-white'>Voir plus</NavLink>
                <a href='#' className='btn text-white'><i class="bi bi-github"></i>&nbsp;Github</a>
                <a href='' className='btn text-white'>Demo</a>
              </center>
            </div>
          </>
        )}

        {(filter === "all" || filter === "web") && (
                    <>
                    <div className='card_projects' align="left">
                        <img src='images/images/ecommerce.jpg'/>
        
                        <h5>E-commerce (Web)</h5>
                        <p>01 Mars 2023</p>
                        <p><b>Plateforme E-Commerce (Symfony)</b></p>
                        <br></br>
                      <center>
                        <NavLink to={'/project/web/zicho'} className='btn text-white'>Voir plus</NavLink>
                        <a href='https://gitlab.com/Youssouf128/zicho-projet' className='btn text-white'><i class="bi bi-github"></i>&nbsp;Github</a>
                        <a href='' className='btn text-white'>Demo</a>
                      </center>
                    </div>
         
                    <div className='card_projects' align="left">
                        <img src='images/images/grh.jpg'/>
        
                        <h5>Gestion du Ressources humaines</h5>
                        <p>07 Janviers 2023</p>
                        <p><b>Application web (J2EE)</b></p>
                        <br></br>
                      <center>
                        <NavLink to={'/project/rh'} className='btn text-white'>Voir plus</NavLink>
                        <a href='https://gitlab.com/Gpestelle/j2ee' className='btn text-white'><i class="bi bi-github"></i>&nbsp;Github</a>
                        <a href='' className='btn text-white'>Demo</a>
                      </center>
                    </div>
                    
                    <div className='card_projects' align="left">
                        <img src='images/images/ia.jpeg'/>

                        <h5>Api CHATGPT</h5>
                        <p>10 Mai 2023</p>
                        <p><b>Application Web</b></p>
                        <br></br>
                      <center>
                        <NavLink to={'/project/chatgpt'} className='btn text-white'>Voir plus</NavLink>
                        <a href='#' className='btn text-white'><i class="bi bi-github"></i>&nbsp;Github</a>
                        <a href='#' className='btn text-white'>Demo</a>
                      </center>
                    </div>

                    <div className='card_projects' align="left">
                        <img src='images/images/sanofi.png'/>
                        
                        <h5>Carnet de l'operateur</h5>
                        <p>18 mars 2024</p>
                        <p><b>Application Web (Node Js & React js)</b></p>
                        <br></br>
                      <center>
                        <NavLink to={'/project/carnet_operateur'} className='btn text-white'>Voir plus</NavLink>
                        <a href='#' className='btn text-white'><i class="bi bi-github"></i>&nbsp;Github</a>
                        <a href='#' className='btn text-white'>Demo</a>
                      </center>
                    </div>
                  </>
        )}

      </div>
    </div>
    </center>
    </>
)};

export default Project_pages_index;