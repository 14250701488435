import React, { useState, useEffect } from 'react';
import { Routes, Route, NavLink } from 'react-router-dom';
import PublicAcceuil from './pages';
import About_pages from './pages/Pages/about';
import Project_pages from './pages/Pages/project';
import Experiences_pages from './pages/Pages/experience';
import Contact_pages from './pages/Pages/contact';

function HomePublic() {

  const [isDarkMode, setIsDarkMode] = useState(false); // State for dark mode
  const [navbarToogle, setNavBarToggle] = useState(false);

  function toggleDarkMode() {
    setIsDarkMode(!isDarkMode);
    document.body.classList.toggle('dark-mode');
  }

  useEffect(() => {
    document.title = "Home | Youssouf Abayazid"
 }, []);
  return (
    <>
      {/* MENU */}
      <nav className="navbar navbar-expand-sm navbar-light">
        <div className="container">
          <NavLink to={'/'} className="navbar-brand2" href="index.html">
            <img src={window.location.origin +'/images/images/yam2.png'} className='img_logo'/>
          </NavLink>

          <button
            type="button"
            data-toggle="collapse"
            data-target="#navbarNav"
            aria-controls="navbarNav"
            aria-expanded={navbarToogle}
            aria-label="Toggle navigation"
            className={`navbar-toggler ${navbarToogle ? "collapsed" : ""}`} onClick={() => setNavBarToggle(!navbarToogle)}
          >
            <span className="navbar-toggler-icon"></span>
            <span className="navbar-toggler-icon"></span>
            <span className="navbar-toggler-icon"></span>
          </button>

          <div className={`collapse navbar-collapse ${navbarToogle ? "show" : ""}`} onClick={() => setNavBarToggle(!navbarToogle)} id="navbarNav">
            <ul className="navbar-nav mx-auto" align="center">
              
              <li className="nav-item">
                <NavLink to={'/'} className="nav-link">
                <i class="fa-solid fa-house"></i>
                  <span><i class="bi bi-house-door-fill"></i>&nbsp;Home</span>
                </NavLink>
              </li>

              <li className="nav-item">
                <NavLink to={'about'} className="nav-link">
                  <span data-hover="À propos"><i class="bi bi-person-fill"></i>&nbsp;À propos</span>
                </NavLink>
              </li>
              
              <li className="nav-item">
                <NavLink to={'project'} className="nav-link">
                  <span data-hover="Projects"><i class="bi bi-bing"></i>&nbsp;Projects</span>
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink to={'experience'} className="nav-link">
                  <span data-hover="Expériences"><i class="bi bi-briefcase-fill"></i>&nbsp;Expériences</span>
                </NavLink>
              </li>
              <li className="nav-item">
                <a href={'documents/CV.pdf'} target='_blank' className="nav-link nav-link-resume">
                  <span data-hover="Contact"><i class="bi bi-download"></i>&nbsp;&nbsp;Resume</span>
                </a>
              </li>
            </ul>

            <ul className="navbar-nav ml-lg-auto">
              <div className="ml-lg-4">
                <div
                  className="color-mode d-lg-flex justify-content-center align-items-center"
                  onClick={toggleDarkMode}
                  style={{ cursor: 'pointer' }}
                >
                  <i className={`color-mode-icon ${isDarkMode ? 'active' : ''}`}></i>
                </div>
              </div>
            </ul>

          </div>
        </div>
      </nav>

      <br />
      <br />
      <br />
      <div className="content-custom">
        <Routes>
          <Route index element={<PublicAcceuil />} />
          <Route path="/" element={<PublicAcceuil />} />
          <Route path="/about" element={<About_pages />} />
          <Route path="/project/*" element={<Project_pages />} />
          <Route path="/experience" element={<Experiences_pages />} />
          <Route path="/contact" element={<Contact_pages />} />
        </Routes>
      </div>

      {/* FOOTER */}
      <footer className="footer py-5">
        <div className="container">
          <div className="row">

            <div className="col-lg-12 col-12 copyright">
              <p className="copyright-text text-center p1">
                &copy;2024 Youssouf. Tous droits réservés.
              </p>

              <div className="custom-btn-group reseau_sociaux p2">
                <ul>
                  <li><a href='https://www.linkedin.com/in/youssouf-abayazid-mohamed-5b2a2121a/'  target='_blanck'><i class="bi bi-linkedin"></i></a></li>
                  <li><a href='https://gitlab.com/Youssouf128' target='_blanck'><i class="bi bi-git"></i></a></li>
                  <li><a href='https://github.com/Youssouf128'  target='_blanck'><i class="bi bi-github"></i></a></li>
                  <li><a href='mailto:youssoufabayazidmohamed@gmail.com'><i class="bi bi-envelope-fill"  target='_blanck'></i></a></li>
                  <li><a href='https://x.com/YoussoufAbayaz1'  target='_blanck'><i class="bi bi-twitter-x"></i></a></li>
                </ul>
              </div>
            </div>

             
          </div>
        </div>
      </footer>
    </>
  );
}

export default HomePublic;
