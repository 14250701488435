import React from 'react';

const Competences = () => {
  return (
    <section
      className="resume py-5 d-lg-flex justify-content-center align-items-center"
      id="resume"
    >
      <div className="container">
        <div className="row">
          {/* Experiences Section */}
          <div className="col-lg-6 col-12">
            <h2 className="mb-4"><img src='images/images/logo/skills.png' className='exp_img'/>&nbsp;Compétences</h2>
          </div>

        <center>
          <div className='containeur_competence'>
                <ul>
                    <li><img src='images/competence/angular.png'/></li>
                    <li><img src='images/competence/c.png'/></li>
                    <li><img src='images/competence/css.png'/></li>
                    <li><img src='images/competence/html.png'/></li>
                    <li><img src='images/competence/docker.png'/></li>
                    <li><img src='images/competence/git.png'/></li>
                    <li><img src='images/competence/java.png'/></li>
                    <li><img src='images/competence/js.png'/></li>
                    <li><img src='images/competence/microsql.png'/></li>
                    <li><img src='images/competence/nextjs.png'/></li>
                    <li><img src='images/competence/node.png'/></li>
                    <li><img src='images/competence/php.png'/></li>
                    <li><img src='images/competence/react.png'/></li>
                    <li><img src='images/competence/sql.png'/></li>
                    <li><img src='images/competence/symfony.png'/></li>
                    <li><img src='images/competence/vuejs.png'/></li>
                    <li><img src='images/competence/python.png'/></li>
                    <li><img src='images/competence/android.png'/></li>
                    <li><img src='images/competence/ps.png'/></li>
                    <li><img src='images/competence/figma.png'/></li>
                    <li><img src='images/competence/jupyter.png'/></li>
                    <li><img src='images/competence/sequelize.png'/></li>
                    <li><img src='images/competence/hibernate.png'/></li>
                </ul>
          </div>
        </center>

        </div>
      </div>
    </section>
  );
};

export default Competences;
