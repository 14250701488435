import React, { useState, useEffect } from 'react';


function Contact_pages() {

  useEffect(() => {
    document.title = "Contact | Youssouf Abayazid"
 }, []);
  return (
    <>
        Contact
    </>
)};

export default Contact_pages;