import React from 'react';
import { NavLink } from 'react-router-dom';

const About = () => {
  return (
    <section
      style={{ paddingTop: '30px' }}
      className="about full-screen d-lg-flex justify-content-center align-items-center"
      id="about"
    >
      <div className="container">
        <div className="row">
          <div className="col-lg-7 col-md-12 col-12 d-flex align-items-center">
            <div className="about-text">
              <h1 className="animated animated-text">
                <span className="mr-2">Je suis, Youssouf ABAYAZID</span>
                <div className="animated-info">
                  <span className="animated-item" style={{ width: '400px' }}>
                    Développeur Full Stack
                  </span>
                  <span className="animated-item">Ingénieur IA</span>
                </div>
              </h1>

              <p>
                Je m'appelle Youssouf Abayazid, titulaire d'un{' '}
                <a
                  href="https://www.univ-littoral.fr/formation/offre-de-formation/masters/master-informatique-ingenierie-des-systemes-informatiques-distribues/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Master en Informatique, spécialité Web et Science de Données
                </a>
                , obtenu à{' '}
                <a
                  href="https://www.univ-littoral.fr/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  l'Université du Littoral Côte d'Opale
                </a>
                . J'ai des compétences solides en développement web et mobile,
                ainsi qu'en création de modèles d'intelligence artificielle. Je
                maîtrise également les techniques d'analyse de données avancées
                en utilisant des technologies de pointe.
              </p>

              <div className="custom-btn-group">
                <a
                  href="documents/CV.pdf" target='_blanck'
                  className="btn mr-lg-2 border_color"
                >
                  <i class="bi bi-download"></i>&nbsp;Télécharger le CV
                </a>
                <a
                  href="mailto:youssoufabayazidmohamed@gmail.com"
                  className="btn border_color"
                >
                 <i class="bi bi-envelope-fill"></i>&nbsp; Contactez-moi
                </a>
              </div>

              <div className="custom-btn-group reseau_sociaux">
                <ul>
                  <li><a href='https://www.linkedin.com/in/youssouf-abayazid-mohamed-5b2a2121a/'  target='_blanck'><i class="bi bi-linkedin"></i></a></li>
                  <li><a href='https://gitlab.com/Youssouf128' target='_blanck'><i class="bi bi-git"></i></a></li>
                  <li><a href='https://github.com/Youssouf128'  target='_blanck'><i class="bi bi-github"></i></a></li>
                  <li><a href='mailto:youssoufabayazidmohamed@gmail.com'><i class="bi bi-envelope-fill"  target='_blanck'></i></a></li>
                  <li><a href='https://x.com/YoussoufAbayaz1'  target='_blanck'><i class="bi bi-twitter-x"></i></a></li>
                </ul>
              </div>

            </div>
          </div>

          <div className="col-lg-5 col-md-12 col-12">
            <div className="about-image svg">
              <img
                src="images/project/profil.png"
                className="img-fluid"
                style={{ borderRadius: '5px' }}
                alt="profil"
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default About;
