import React from 'react';

const Projects = () => {
  return (
    <section className="project py-5" id="project">
      <div className="container">
        <div className="row">
          <div className="col-lg-11 text-center mx-auto col-12">
            <div className="col-lg-8 mx-auto">
              <h2>Que fais-je ?</h2>
            </div>
            <br/>
            <br/>
            <div className="container activite">
                <div className="row">
                    <div className="col">
                        <img src='images/images/logo/front.png' className='frontend'/>  
                        <h4>Développement Frontend</h4>
                        <p>
                          Je peux vous aider à créer des applications frontend
                            fonctionnelles et réactives pour le web avec des
                            technologies modernes.
                        </p>
                    </div>
                    <div className="col">
                        
                        <img src='images/images/logo/back.png' className='backend'/> 
                        <h4>Développement Backend</h4>
                        <p>
                           Je peux vous aider à construire des services backend de haute qualité pour vos applications, afin de les faire évoluer et répondre aux besoins de vos client.
                        </p>
                    </div>
                    <br></br>
                    <div className="w-100"></div>
                    <div className="col">
                        
                        <img src='images/images/logo/design.png' className='design'/> 
                        <h4>Conception Ui/Ux</h4>
                        <p>
                          Je peux vous aider à concevoir une interface utilisateur attrayante et conviviale pour votre application ou site Web.
                        </p>
                    </div>
                    <div className="col">
                        
                        <img src='images/images/logo/phone.png' className='phone'/> 
                        <h4>Développement Mobile</h4>
                        <p>
                        Je peux vous aider à créer des applications frontend
                            fonctionnelles et réactives pour le web avec des
                            technologies modernes.
                        </p>
                    </div>

                    <br></br>
                    <div className="w-100"></div>
                    <div className="col">
                        
                        <img src='images/images/logo/statistique.png' className='statistique'/> 
                        <h4>Analyse de données</h4>
                        <p>
                          Je peux vous aider à analyser les données en utilisant des outils puissants tels que Power BI, Spark, et Druid pour le big data, ou simplement avec Python dans un Jupyter Notebook.
                        </p>
                    </div>
                    <div className="col">
                        
                        <img src='images/images/logo/robot.png' className='phone'/> 
                        <h4>Intelligence artificielle</h4>
                        <p>
                          Je peux vous aider à créer des modèles d'IA, par exemple en entraînant un modèle sur vos données pour automatiser certaines tâches en utilisant l'apprentissage automatique ou d'autres types d'apprentissage. Je peux également développer des modèles basés sur des IA existantes, notamment en intégrant des API comme GPT.
                        </p>
                    </div>


                </div>
            </div>

          </div>
        </div>
      </div>
    </section>
  );
};

export default Projects;
