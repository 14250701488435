import React, { useState, useEffect } from 'react';


function Experiences_pages() {
  useEffect(() => {
    document.title = "Expérience | Youssouf Abayazid"
 }, []);
  return (
    <>
    
    <div className="col-lg-6 col-12">
        <h2 className="mb-4"><img src='images/images/logo/portfolio.png' className='exp_img'/>&nbsp;Expériences</h2>
    </div> 

    <section
      className="resume py-5 d-lg-flex justify-content-center align-items-center"
      id="resume"
    >
      <div className="container">
        <div className="row">

          {/* Educations Section */}
          <div className="col-lg-9 col-12">
          
            <div className="timeline">
              <div className="timeline-wrapper">
                <div className="timeline-yr">
                  <img src='images/images/logo/sanofi.jpg'/>
                </div>
                <div className="timeline-info">
                  <h3>
                    <span>Application web (stage de fin d’etude 6 mois) </span>
                    <br></br>
                    <small><a href='https://www.sanofi.fr/fr' target='_blank' id='a_small'><b>Sanofi chimie</b>&nbsp;,30390 Aramon , France&nbsp;&nbsp;<i class="bi bi-patch-check-fill"></i></a></small>
                    <br></br><small>(18 Mars 2024 à 23 Aout 2024)</small>
                  </h3>
                  <br/>
                    <ul align="left" id='ul_experience'>
                      <li>La rédaction du cahier des charges</li>
                      <li>Modélisation de la base de données.</li>
                      <li>Début du développement de l'application web pour la
                      bibliothèque LMS Aramon</li>
                      <li>Travail en binôme selon le modèle de projet Agile avec CI/CD.</li>
                      <li>Mise en place de tests unitaires et fonctionnels. (Tests unitaires,
                        Tests fonctionnels).</li>
                      <li>Déploiement de l'application en production.</li>
                      <li>Technologies utilisées : Node.js (back-end), ORM Sequelize,
                        ReactJS (front-end), Bootstrap, Docker et SQL Server.
                        </li>
                    </ul>
                    <hr></hr>
                  <br/>
                  <ul align="right" className='ul_resume'>
                    <li><p>Node js</p></li>
                    <li><p>React Js</p></li>
                    <li><p>API Restful</p></li>
                    <li><p>CKEditor</p></li>
                    <li><p>HTML5</p></li>
                    <li><p>CSS</p></li>
                    <li><p>Typescript</p></li>
                    <li><p>Bootstrap</p></li>
                    <li><p>Microsoft Sql Server</p></li>
                    <li><p>CI/CD</p></li>
                    <li><p>Docker</p></li>
                    <li><p>Sequelize</p></li>
                    <li><p>Agilité</p></li>
                  </ul>
                </div>
              </div>

              <div className="timeline-wrapper">
                <div className="timeline-yr">
                <img src='images/images/logo/cle.jpg'/>
                </div>
                <div className="timeline-info">
                  <h3>
                    <span>Stage (6 mois)</span>
                    <br></br>
                    <small><a href='https://www.cledjibouti.com/' target='_blank' id='a_small'>Centre de Leadership et de l’Entrepreneuriat , Djibouti ville&nbsp;&nbsp;<i class="bi bi-patch-check-fill"></i></a></small>
                    <br></br>
                    <small>(Juin 2021 - Décembre 2021)</small>
                  </h3>
                  <br/>
                  <ul align="left" id='ul_experience'>
                      <li>Modélisation de la base de données.</li>
                      <li>Début du développement de l'application e-commerce</li>
                      <li>Technologies utilisées : Implémentation d'Elasticsearch via Docker,
                          PHP Symfony, Bootstrap, SQL, HTML, CSS, JavaScript
                      </li>
                    </ul>
                    <hr></hr>
                  <ul align="right" className='ul_resume'>
                    <li><p>HTML5</p></li>
                    <li><p>CSS</p></li>
                    <li><p>Agilité</p></li>
                    <li><p>PHP</p></li>
                    <li><p>MySQL</p></li>
                    <li><p>Javascript</p></li>
                  </ul>
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>
    </section>
    </>
)};

export default Experiences_pages;