import React from 'react';

const Resume = () => {
  return (
    <section
      className="resume py-5 d-lg-flex justify-content-center align-items-center"
      id="resume"
    >
      <div className="container">
        <div className="row">

          {/* Educations Section */}
          <div className="col-lg-9 col-12">
            <h2 className="mb-4 mobile-mt-2"><img src='images/images/logo/education.png' className='exp_img2'/>&nbsp;Educations</h2>

            <div className="timeline">
              <div className="timeline-wrapper">
                <div className="timeline-yr">
                  <img src='images/images/logo/ulco.png'/>
                </div>
                <div className="timeline-info">
                  <h3>
                    <span>Master 2 ( web & science de données )</span>
                    <small><a href='https://www.univ-littoral.fr/formation/offre-de-formation/masters/master-informatique-ingenierie-des-systemes-informatiques-distribues/' target='_blank' id='a_small'>Université de littoral cote d'opale&nbsp;<i class="bi bi-patch-check-fill"></i></a></small>
                    <small>(septembre 2023 - septembre 2024)</small>
                  </h3>
                  <br/>
                  <ul align="right" className='ul_resume'>
                    <li><p>HTML5</p></li>
                    <li><p>JavaScript</p></li>
                    <li><p>Spring Boot</p></li>
                    <li><p>TypeScript</p></li>
                    <li><p>BIG DATA</p></li>
                    <li><p>Docker</p></li>
                    <li><p>Vue js</p></li>
                    <li><p>J2EE</p></li>
                    <li><p>Node Js</p></li>
                    <li><p>React js</p></li>
                    <li><p>C++</p></li>
                    <li><p>DevOps</p></li>
                    <li><p>Gitlab (CI/CD)</p></li>
                    <li><p>Sécurité réseaux</p></li>
                    <li><p>Kotlin</p></li>
                    <li><p>React Native</p></li>
                    <li><p>Flutter</p></li>
                    <li><p>Kubernetes</p></li>
                    <li><p>MICROSOFT SQL Server</p></li>
                    <li><p>API REST</p></li>
                    <li><p>SQL</p></li>
                    <li><p>Apprentissage automatique</p></li>
                    <li><p>API GATEWAY</p></li>
                    <li><p>MySQL</p></li>
                  </ul>
                </div>
              </div>

              <div className="timeline-wrapper">
                <div className="timeline-yr">
                <img src='images/images/logo/ulco.png'/>
                </div>
                <div className="timeline-info">
                  <h3>
                    <span>Master 1 ( web & science de données )</span>
                    <small><a href='https://www.univ-littoral.fr/formation/offre-de-formation/masters/master-informatique-ingenierie-des-systemes-informatiques-distribues/' target='_blank' id='a_small'>Université de littoral cote d'opale&nbsp;<i class="bi bi-patch-check-fill"></i></a></small>
                    <small>(septembre 2022 - septembre 2023)</small>
                  </h3>
                  <br/>
                  <ul align="right" className='ul_resume'>
                    <li><p>Java</p></li>
                    <li><p>Haskell</p></li>
                    <li><p>Agilité</p></li>
                    <li><p>Symfony</p></li>
                    <li><p>API REST</p></li>
                    <li><p>Base de données</p></li>
                  </ul>
                </div>
              </div>

              <div className="timeline-wrapper">
                <div className="timeline-yr">
                <img src='images/images/logo/djibouti.jpg'/>
                </div>
                <div className="timeline-info">
                  <h3>
                    <span>Licence Informatique</span>
                    <small><a href='' id='a_small'>Université de Djibouti&nbsp;<i class="bi bi-patch-check-fill"></i></a></small>
                    <small>(2019 - 2021)</small>
                  </h3>
                  <br/>
                  <ul align="right" className='ul_resume'>
                    <li><p>Base de données</p></li>
                    <li><p>Langage C</p></li>
                    <li><p>PHP</p></li>
                    <li><p>MySQL</p></li>
                    <li><p>Oracle</p></li>
                    <li><p>Html</p></li>
                    <li><p>CSS</p></li>
                    <li><p>JavaScript</p></li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Resume;
