import React, { useState, useEffect } from 'react';
import About from './Home/About';
import Projects from './Home/Projects';
import Contact from './Home/Contact';
import Resume from './Home/Resume';
import Competences from './Home/Competences';

function PublicAcceuil() {
  useEffect(() => {
    document.title = "Portfolio | Youssouf Abayazid"
 }, []);
  return (
    <>
      <About></About>
      <Projects></Projects>
      <Competences></Competences>
      <Resume></Resume>
      <Contact></Contact>
    </>
)}; 

export default PublicAcceuil;