import React, { useState, useEffect } from 'react';


function About_pages() {
  useEffect(() => {
    document.title = "A propos | Youssouf Abayazid"
 }, []);
  return (
    <>
      <div className='container'>
        <div class="row">
          
            <div class="col">
              <img src="images/project/profil.png" className="img-fluid" style={{ borderRadius: '5px' }} alt="profil" />
            </div>
          <div class="col">
            <h4>Qui suis-je</h4>
            <p>
                Je m'appelle Youssouf Abayazid, titulaire d'un{' '}
                <a
                  href="https://www.univ-littoral.fr/formation/offre-de-formation/masters/master-informatique-ingenierie-des-systemes-informatiques-distribues/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Master en Informatique, spécialité Web et Science de Données
                </a>
                , obtenu à{' '}
                <a
                  href="https://www.univ-littoral.fr/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  l'Université du Littoral Côte d'Opale
                </a>
                . J'ai des compétences solides en développement web et mobile,
                ainsi qu'en création de modèles d'intelligence artificielle. Je
                maîtrise également les techniques d'analyse de données avancées
                en utilisant des technologies de pointe.
              </p>

            <br/>
            <h4>Info personnelles</h4>
            <br></br>

            <div className="row about_contact" >
              <div className="col">
                <span>
                  <i class="bi bi-telephone"></i>
                </span>
                <b>Téléphone</b><br></br>
                &nbsp;
                <a href='tel:+(33) 7 44 61 24 46'>+(33) 7 44 61 24 46</a>
              </div>
              <div className="col">
                <span>
                  <i class="bi bi-geo-alt"></i>
                </span>
                <b>Location</b><br></br>
                &nbsp;
                <a href=''>Avignon, 84000</a>
              </div>
            </div>
            <br></br>

            <div className="row about_contact" >
              <div className="col">
                <span>
                 <i class="bi bi-envelope"></i>
                </span>
                <b>Email</b><br></br>
                &nbsp;
                <a href='mailto:youssoufabayazidmohamed@gmail.com'>youssoufabayazidmohamed@gmail.com</a>
              </div>
            </div>
            <br></br>
            <div className="row about_contact" >
              <div className="col">
                <span>
                 <i class="bi bi-car-front-fill"></i>
                </span>
                <b>Permis B</b><br></br>
                &nbsp;
                <a href='#'>Vehiculé</a>
              </div>
            </div>

            <div className="custom-btn-group reseau_sociaux">
                <ul>
                  <li><a href='https://www.linkedin.com/in/youssouf-abayazid-mohamed-5b2a2121a/'  target='_blanck'><i class="bi bi-linkedin"></i></a></li>
                  <li><a href='https://gitlab.com/Youssouf128' target='_blanck'><i class="bi bi-git"></i></a></li>
                  <li><a href='https://github.com/Youssouf128'  target='_blanck'><i class="bi bi-github"></i></a></li>
                  <li><a href='mailto:youssoufabayazidmohamed@gmail.com'><i class="bi bi-envelope-fill"  target='_blanck'></i></a></li>
                  <li><a href='https://x.com/YoussoufAbayaz1'  target='_blanck'><i class="bi bi-twitter-x"></i></a></li>
                </ul>
              </div>


          </div>
        </div>
        <br></br>
        <center>
          <section className="contact py-5" id="contact">
            <div className="container">
              <div className="row">
                
                  <div className="Contacte">
                      <button className='btn button'><i class="bi bi-rocket-takeoff-fill"></i>&nbsp;Connectez-vous avec moi</button>
                  </div>
              </div>
            </div>
          </section>
        </center>

      </div>
    </>
)};

export default About_pages;