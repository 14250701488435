import React from 'react';
import { NavLink } from 'react-router-dom';

const Contact = () => {
  return (
    <center>
      <section className="contact py-5" id="contact">
        <div className="container">
          <div className="row">
            
              <div className="Contacte">
                  <NavLink to={'/about'} className='btn text-white button'><i class="bi bi-rocket-takeoff-fill"></i>&nbsp;Connectez-vous avec moi</NavLink>
              </div>
          </div>
        </div>
      </section>
    </center>
  );
};

export default Contact;
