import React, { useState, useEffect } from 'react';
import Project_pages_index from './pages_project';
import { Routes, Route, NavLink } from 'react-router-dom';
import Ecommerce_mobile_Index from './pages_project/mobile/ecommerce';
import Qcm_mobile_Index from './pages_project/mobile/qcm';
import Rh_Index from './pages_project/web/rh';
import Chatgpt_Index from './pages_project/web/chatgpt';
import Sanofi_Index from './pages_project/web/sanofi';
import Ecommerce_Index from './pages_project/web/ecommerce';

function Project_pages() {
  
  useEffect(() => {
    document.title = "Project | Youssouf Abayazid"
 }, []);

  return (
    <>
      <Routes>
          <Route index element={<Project_pages_index />} />
          <Route path="/ecommerce" element={<Ecommerce_mobile_Index />} />
          <Route path="/web/zicho" element={<Ecommerce_Index />} />
          <Route path="/qcm" element={<Qcm_mobile_Index />} />
          <Route path="/rh" element={<Rh_Index />} />
          <Route path="/chatgpt" element={<Chatgpt_Index />} />
          <Route path="/carnet_operateur" element={<Sanofi_Index />} />
        </Routes>
    </>
)};

export default Project_pages;