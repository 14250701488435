import React, { useState, useEffect } from 'react';

function Rh_Index() {
  
  return (
    <>
      <div className="col-lg-6 col-12">
        <h2 className="mb-4"><img src={window.location.origin +'/images/images/logo/portfolio.png'} className='exp_img'/>&nbsp;Techniques</h2>
      </div>

    <center>
        <div className='technique'>
            <img src={window.location.origin +'/images/images/grh.jpg'}/>
        </div>

        <div className='container tags' align="left">
            <h3>Gestion du Ressources humaines</h3>
            

            <ul className="ul_tags">
                <li>DevOps</li>
                <li>J2EE</li>
                <li>HTML5</li>
                <li>CSS</li>
                <li>Vue js</li>
                <li>Bootstrap</li>
                <li>MySQL</li>
                <li>CI/CD</li>
                <li>ORM Hibernate</li>
            </ul>
            <b>07 Janviers 2023</b>
            <hr></hr>
            <p>
          <br></br>
L'idée derrière ce projet était de travailler en équipe en utilisant la méthode Scrum. Pour le module d'agilité, nous avons dû nous diviser en groupes de six, chacun étant responsable du développement d'une fonctionnalité de l'application et de la création d'une API correspondante. À la fin, nous avons regroupé toutes ces API pour en faire une API Gateway.

<br></br>
<br></br>
L'objectif était de comprendre comment une équipe s'organise et collabore dans un cadre professionnel, avec des réunions régulières pour suivre l'avancement.

<br></br>
<br></br>
Nous avons travaillé en binôme sur le système de gestion des ressources humaines, ce qui impliquait de créer nos propres API, de les mettre en production, et de collaborer en utilisant Git dans un flux CI/CD.
            </p>
        </div>
    </center>
    </>
)};

export default Rh_Index;