// App.js
import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, NavLink, useNavigate } from 'react-router-dom';
import AdminDashboard from './components/Admin/AdminDashboard';
import HomePublic from './components/Public/HomePublic';
import LoginPage from './components/Public/login/index';

//import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min";
import "bootstrap-icons/font/bootstrap-icons.css";
import "bootstrap-icons/font/bootstrap-icons.min.css";

import axios from 'axios';
//import Login from './Login';
//import { PublicRoute , FileRoute } from './Utils/PublicRoute';
import { getToken, removeUserSession, setUserSession } from './Utils/Common';



function App() {

  let isAuthenticated = false;

  const [authLoading, setAuthLoading] = useState(true);


  return (
  <Routes> 
    <Route path="/*" element={<HomePublic />} ></Route>
  </Routes>
);
}

export default App;
